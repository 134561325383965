<template>
  <div class="row">
    <div class="col-12">
      <br>
      <!-- 비상조치계획서 -->
      <c-upload 
        :attachInfo="attachInfo"
        label="비상조치계획서"
        :editable="editable&&!disabled">
      </c-upload>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
export default {
  name: 'emergency-action-file',
  props: {
    emerActPlanDocu: {
      type: Object,
      default: () => ({
        sopEmergencyPlanId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      editable: true,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EMERGENCY_PLAN',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.attachInfo.taskKey = this.$_.clone(this.emerActPlanDocu.sopEmergencyPlanId)
      this.attachInfo.isSubmit = uid()
    },
  }
};
</script>
